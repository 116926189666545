import React from 'react'
import CheckIcon from '../../styles/icons/check'

const items = [
    "Quick WFH Setup",
    "Real-Time Monitoring",
    "App & Website Usage",
    "Screenshots",
    "Time & Attendance",
    "Reports",
]

function Remote() {
    return (
        <section className="pt-4">
            <div className="container mx-auto">
                <div className="flex flex-wrap lg:justify-between">
                    <img src="/graphics-4.svg"></img>
                    <div className="max-w-md space-y-5">
                        <div>
                            <h6 className="heading">Perfect solution for distributed teams</h6>
                            <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14">
                                Delivering productivity to every member of your <span className="bg-shape-light">remote team</span>
                            </h1>
                        </div>
                        <p className="text-body-muted text-sm lg:text-lg mx-auto">
                            Monitor remote workers as if they’re on the desk right next to you. Simple, accurate time tracking and proof of work to ensure they’re doing the job.
                        </p>
                        <ul className="grid grid-cols-2 gap-3">
                            {items.map(i => (
                                <li key={i} className="flex items-center space-x-2  text-heading-dark text-sm font-semibold">
                                    <CheckIcon />
                                    <span>{i}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Remote
