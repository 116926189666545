import React from 'react'

export default function Hero() {
    return (
        <section className="space-y-4">
            <div className="container mx-auto lg:pt-32 md:pt-16 pt-8 space-y-4 ">
                <div className="flex flex-wrap lg:justify-between">
                    <img className="lg:transform lg:-translate-y-16" src="/graphics-6.svg"></img>
                    <div className="max-w-md space-y-4">
                        <h6 className="text-success font-bold text-sm">SOFTWARE APPLICATIONS USAGE</h6>
                        <h2 className="text-body-darker text-2xl font-semibold">Productivity Monitoring</h2>
                        <p className="text-sm lg:text-lg mx-auto">
                            Want to know if your employees are using the right software applications during their work hours? You get complete breakdown with the list of applications and time spent on each of them.
                        </p>
                        <div className="lg:pt-7 pt-0">
                            <p className="text-body-muted">
                                “I’ve had @Handdy pro for about 24 hours now and I honestly don't know how I functioned without it before”
                            </p>
                            <figure className="flex space-x-2 mt-4">
                                <img src="/avatar-3.png"></img>
                                <figcaption className="flex space-x-2">
                                    <p className="font-bold">Allen Soto</p>
                                    <p className="text-body-muted"> - Product Designer</p>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mx-auto pt-4 space-y-4">
                <div className="flex flex-wrap lg:justify-between flex-col-reverse lg:flex-row md:flex-row sm:flex-row">
                    <div className="max-w-md space-y-4">
                        <h6 className="text-danger-light font-bold text-sm">WEBSITES AND PORTALS VISITED</h6>
                        <h2 className="text-body-darker text-2xl font-semibold">Employee Monitoring</h2>
                        <p className="text-sm lg:text-lg mx-auto">
                            You can track all your employee’s activity on their PC, websites they visit, their keystrokes, screenshots and more. With these, you can easily identify your star performers from the rest.
                        </p>
                        <div className="lg:pt-7 pt-0">
                            <p className="text-body-muted">
                                “I’ve had @Handdy pro for about 24 hours now and I honestly don't know how I functioned without it before”
                            </p>
                            <figure className="flex space-x-2 mt-4">
                                <img src="/avatar-3.png"></img>
                                <figcaption className="flex space-x-2">
                                    <p className="font-bold">Allen Soto</p>
                                    <p className="text-body-muted"> - Product Designer</p>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                    <img className="mt-4" src="/graphics-7.svg"></img>
                </div>
            </div>
            <div className="container mx-auto lg:pt-20 md:pt-16 pt-8 space-y-4">
                <div className="flex flex-wrap lg:justify-between">
                    <img className="lg:transform lg:-translate-y-16" src="/graphics-8.svg"></img>
                    <div className="max-w-md space-y-4">
                        <h6 className="text-info-light font-bold text-sm">POWERFUL REPORTS FOR ANALYSIS</h6>
                        <h2 className="text-body-darker text-2xl font-semibold">Workforce Analytics</h2>
                        <p className="text-sm lg:text-lg mx-auto">
                            Tracking employee behaviour at a granular level is difficult for most companies. With Handdy, you get a complete picture at every level including staff, team, task, and project level.
                        </p>
                        <div className="lg:pt-7 pt-0">
                            <p className="text-body-muted">
                                “I’ve had @Handdy pro for about 24 hours now and I honestly don't know how I functioned without it before”
                            </p>
                            <figure className="flex space-x-2 mt-4">
                                <img src="/avatar-3.png"></img>
                                <figcaption className="flex space-x-2">
                                    <p className="font-bold">Allen Soto</p>
                                    <p className="text-body-muted"> - Product Designer</p>
                                </figcaption>
                            </figure>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}


