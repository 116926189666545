import React, { useState } from 'react'
import LineIcon from '../../styles/icons/line'
import ArrowIcon from '../../styles/icons/arrow'
import PricingCards from './cards'
import { Link } from 'gatsby'

function Pricing() {
    const [isMonthly, setIsMonthly] = useState(false)
    return (
        <section className="lg:py-32 md:py-24 sm:py-16 py-4">
            <div className="container mx-auto grid gap-y-4">
                <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14 text-center max-w-lg mx-auto">
                    Choose the plan that’s right for your business
                </h1>
                <p className="text-body-light text-sm lg:text-lg text-center">
                    30-day free trial, cancel any time. No credit card required.
                </p>
                <div className="flex items-center space-x-4 justify-center flex-wrap">
                    <label className={!isMonthly && 'text-body-muted'} htmlFor="switch">Bill Monthly</label>
                    <input
                        defaultChecked={!isMonthly}
                        onChange={() => setIsMonthly(!isMonthly)}
                        id="switch"
                        name="switch"
                        type="checkbox" className="switch"></input>
                    <label className={isMonthly && 'text-body-muted'} htmlFor="switch">Bill Annually</label>
                    <p className="text-primary-dark font-bold text-base relative">
                        Save 15%
                            {!isMonthly &&
                            <LineIcon className="absolute -bottom-1" />
                        }
                    </p>
                </div>
                <PricingCards />
                <p className="text-body-light text-sm lg:text-lg text-center">See how an all-in-one Handdy plan stacks up against the competition and saves you money</p>
                <div className="flex items-center space-x-2 mx-auto justify-center">
                    <Link to="/pricing" className="text-primary text-xs font-semibold text-center">Compare all plans, and features</Link>
                    <span role="icon"><ArrowIcon /></span>
                </div>
                <div className="grid bg-secondary gap-3 lg:grid-cols-3 md:grid-cols-3 items-center justify-items-center lg:py-6 lg:px-0 p-4 rounded-xl lg:mt-16 md:mt-12 sm:mt-8 mt-0">
                    <h4 className="lg:text-lg md:text-lg text-base text-heading-dark font-bold">We're here to help</h4>
                    <p className="text-tiny lg:text-base md:text-base max-w-sm">
                        Would you like a free personalised demo to learn
                        how you can use Handdy at your company?
                    </p>
                    <button className="focus:outline-none font-semibold text-white text-sm h-10 rounded-md bg-primary px-5 box-shadow">Book your free demo now</button>
                </div>
            </div>
        </section >
    )
}

export default Pricing
