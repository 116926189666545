import React from "react";

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="none"
            viewBox="0 0 16 16"
        >
            <path
                fill="#0BAD4B"
                fillRule="evenodd"
                d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
                clipRule="evenodd"
            ></path>
            <path
                fill="#0BAD4B"
                fillRule="evenodd"
                d="M4 8a.5.5 0 00.5.5h5.793l-2.147 2.146a.5.5 0 00.708.708l3-3a.5.5 0 000-.708l-3-3a.5.5 0 10-.708.708L10.293 7.5H4.5A.5.5 0 004 8z"
                clipRule="evenodd"
            ></path>
        </svg>
    );
}

export default Icon;
