import React from 'react'

const items = [
    { title: "Monitor websites visited and time spent", icon: "timer" },
    { title: "Applications usage and productivity reports", icon: "folder" },
    { title: "Get screen shots of employee activity", icon: "graph" },
    { title: "Track employee work and break times", icon: "pointer" },
    { title: "Review employee keyboard usage", icon: "note" },
    { title: "Track employee attendance", icon: "ss" },
    { title: "Get project level timesheet reports", icon: "pie" },
    { title: "Monitor employees with or without internet", icon: "project" },
]

function Features() {
    return (
        <section className="bg-secondary lg:mx-11 md:mx-9 rounded-t-3xl rounded-b-xl lg:mt-40 mt:my-32 st:my-20 mt-7">
            <div className="container mx-auto space-y-4 lg:space-y-8 lg:py-40 md:py-32 sm:py-20 py-7 lg:px-24 md:px-16 sm:px-12">
                <div className="grid gap-4 lg:gap-14 md:gap-14 lg:grid-cols-2 md:grid-cols-2 items-center">
                    <div className="grid max-w-xs">
                        <h6 className="heading">Features</h6>
                        <h1 className="text-heading-dark text-2xl lg:text-5xl md:text-4xl sm:text-3xl lg:leading-14">
                            All-in-one
                            workforce
                            management
                        </h1>
                    </div>
                    <p className="text-body-light text-sm lg:text-lg max-w-md lg:justify-self-end">Handdy collects employee work patterns in a granular level providing companies with analytics, visibility, and context.
Now you can gain insights and unlock the true potential of your workforce. Handdy makes workforce productivity management simple and affordable.</p>
                </div>
                <ul className="list-wrapper">
                    {items.map(i => (
                        <li key={i.title}>
                            <figure className="flex items-center space-x-6">
                                <img width="80" height="80" src={`/icons/${i.icon}.svg`} />
                                <figcaption>
                                    <h6 className="text-heading-dark font-bold lg:text-lg">{i.title}</h6>
                                </figcaption>
                            </figure>
                        </li>
                    ))}
                </ul>
            </div>
        </section>
    )
}

export default Features
